import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  title
} from "assets/jss/material-kit-react.jsx";

const infoStyle = {
  infoArea: {
    maxWidth: "360px",
    margin: "0 auto",
    padding: "20px"
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px"
  },
  primary: {
    color: primaryColor
  },
  warning: {
    color: warningColor
  },
  danger: {
    color: dangerColor
  },
  success: {
    color: successColor
  },
  info: {
    color: infoColor
  },
  rose: {
    color: roseColor
  },
  gray: {
    color: grayColor
  },
  icon: {
    width: "36px",
    height: "36px"
  },
  descriptionWrapper: {
    color: grayColor,
    overflow: "hidden"
  },
  title,
  description: {
    color: "#000000",
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "11px",
    fontWeight: "600",
    textTransform: "uppercase",
    "@media (max-width: 800px)": {
      fontSize: "10px",
    },

  },
  iconWrapperVertical: {
    float: "none"
  },
  
  iconVertical: {
    width: "45px",
    height: "45px",
    borderRadius: "50%",
    width: "100px",
    height: "100px",
    padding: "16px",
    border: "5px solid #6396b7",
    background: "linear-gradient(137deg, #edeff1 40%, #d2deef 80%)!important",
  },
};

export default infoStyle;
